body {
  --theme-color: black;

  background-color: white;
  color: var(--theme-color);

  margin-left: 15vw;
  margin-right: 15vw;
}

.App {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15vh;
}

h1, h2, h3 {
  color: #162335
}

a:link,
a:visited {
  color: black;
  text-underline-position: under
}

a:hover {
  font-weight: 500
}
a:active {
  background-color: var(--theme-color);
  color: white
}

p {
  font-size: 20px
}

.logo {
  width: 150px;
  margin-bottom: 10vh;
}

